import React, { useEffect, Fragment } from "react";
import CTA from "../general/cta";
import EntryIllustration from "../../../../assets/img/kuda-betting.inline.svg";
import { scrollToElement } from "../../../utility/utils";
import BelowFoldCards from "../general/belowFoldCards";
import BadgesEntryFold from "../general/badgesEntryFold";
import KudaAppIcon from "../../../../assets/img/kuda-app-miniIcon.inline.svg"
import MoneyIcon from "../../../../assets/img/withdrawIcon.inline.svg"
import PhoneIcon from "../../../../assets/img/airtime-icon-blue.inline.svg"
import FirstSectionImage from "../../../../assets/img/kuda-betting-providers.inline.svg"
import SecondSectionImage from "../../../../assets/img/kuda-sportybet.inline.svg"
import ThirdSectionImage from "../../../../assets/img/money-wallet.inline.svg"
import ImageTextRight from "../general/imageTextRight";
import ImageTextLeft from "../general/imageTextLeft";
import FAQList from "../general/faqsList";
import MoreForYouComponent from "../general/more-for-you";

import KudaInternetIcon from '../../../../assets/img/more-for-you/kuda-internet-icon.inline.svg'
import KudaAirtimeIcon from '../../../../assets/img/more-for-you/kuda-airtime-icon.inline.svg'
import BorrowIcon from "../../../../assets/img/more-for-you/kuda-borrow-icon.inline.svg"

const entryContent = {
    title: "Top up your favourite betting wallet easily on the Kuda app.",
    subtitle: "Don’t miss out on betting action! Find popular betting wallets and add money to any wallet quickly on Kuda.",
    illustration: <EntryIllustration/>
}
const kudaTopFeatures = [
    {
        icon: <KudaAppIcon />,
        text: "Find popular Nigerian betting wallets on the Kuda app.",
    },
    {
        icon: <MoneyIcon />,
        text: "Top up your favourite betting wallet directly from your Kuda account.",
    },
    {
        icon: <PhoneIcon />,
        text: "Choose your preferred betting package in seconds.",
    },
]
const firstSection = {
    title: "Find popular betting wallets on the Kuda app.",
    subtitle: "Top up your SportyBet, Betway, MerryBet, BetKing, Bet9ja, 1xBet or NairaBet wallet directly from your account for online betting on games.",
    url: "/joinKuda/",
    name: "Join Kuda",
    illustration: <FirstSectionImage />,
}
const secondSection = {
    title: "Add money to your betting wallet any time.",
    subtitle: "Need to make a last-minute bet on a game? Top up your online betting wallet on the Kuda app with just a few taps.",
    url: "https://help.kuda.com/support/solutions/articles/73000560712-add-money-to-your-betting-wallet",
    name: " Learn How To Top Up A Wallet",
    illustration: <SecondSectionImage />,
}
const thirdSection = {
    title: "Control your betting with smaller amounts.",
    subtitle: "Go as low as you want. Top up your wallet for online betting on games with the minimum accepted amount on Kuda.",
    url: "https://kuda.onelink.me/abUI/344e3dde",
    name: "Download Kuda",
    illustration: <ThirdSectionImage />,
}

const questions = [
    {
        heading: "Which betting wallets can I top up on the Kuda app?",
        list: (
            <span className="flex flex-column inApp-contact-ways--wrap">
                <span className="faq-description f-16">You can top up SportyBet, Betway, MerryBet, BetKing, Bet9ja, 
                    1xBet and NairaBet wallets directly from your account on the Kuda app.

                </span>

            </span>),
        index: 1
    },
    {
        heading: "How do I top up a betting wallet on the Kuda app?",
        list: (
            <span className="flex flex-column">
                <span>To top up a betting wallet, follow the steps below:</span>
                <span className="pt-3 career-message-margin-bottom ">
                    <div className="flex flex-column  leading-md-6 f-md-16">
                        <div className="mb-3">1. Sign in to your Kuda app, then tap Pay.</div>
                        <div className="mb-3">2. Tap Pay A Bill.</div>
                        <div className="mb-3">3. Tap Betting.</div>
                        <div className="mb-3">4. Tap the Service Provider dropdown menu.</div>
                        <div className="mb-3">5. Choose your service provider (betting wallet).</div>
                        <div className="mb-3">6. Tap the Package dropdown menu.</div>
                        <div className="mb-3">7. Choose your betting package.</div>
                        <div className="mb-3">8. Type in your betting wallet ID.</div>
                        <div className="mb-3">9. Type in the amount you'd divke to add to your wallet.</div>
                        <div className="mb-3">10. Confirm the transaction with your transaction ID, fingerprint or Face ID.</div>
                        <div className="mb-3">11. Tap Done to return to your Kuda dashboard.</div>
                    </div>
                </span>
            </span>),
        index: 2
    }
]

const moreForYou = [
    {
        icon: <KudaInternetIcon />,
        title: "Internet",
        subText: `Buy internet data from major Nigerian internet service providers easily on your Kuda app.`,
        linkTo: `/en-ng/bill-payments/internet/`
    },
    {
        icon: <KudaAirtimeIcon />,
        title: "Airtime",
        subText: `Buy Airtel, Glo, MTN or 9Mobile airtime directly from your account on your Kuda app.`,
        linkTo: `/en-ng/airtime/`
    },
    {
        icon: <BorrowIcon />,
        title: "Loans",
        subText: `Get up to ₦150,000 in your Kuda account easily and repay in convenient instalments.`,
        linkTo: `/en-ng/personal-loan/`
    },

]

const Betting = () => {

    useEffect(() => {
        scrollToElement();
        window.addEventListener("scroll", function () {
            scrollToElement();
        });
    }, [])

    return (
        <Fragment>
            <BadgesEntryFold
                title={entryContent.title}
                subtitle={entryContent.subtitle}
                illustration={entryContent.illustration}
            />
            <BelowFoldCards topFeatures={kudaTopFeatures} />
            <ImageTextRight
                title={firstSection.title}
                subtitle={firstSection.subtitle}
                illustration={firstSection.illustration}
                name={firstSection.name}
                url={firstSection.url}
            />
            <ImageTextLeft
                title={secondSection.title}
                subtitle={secondSection.subtitle}
                illustration={secondSection.illustration}
                name={secondSection.name}
                url={secondSection.url}
            />
            <ImageTextRight
                title={thirdSection.title}
                subtitle={thirdSection.subtitle}
                illustration={thirdSection.illustration}
                name={thirdSection.name}
                url={thirdSection.url}
            />
            <FAQList title={"Betting FAQs"} questions={questions} />
            <MoreForYouComponent moreForYou={moreForYou}/>
            <CTA />
        </Fragment>
    )
}

export default Betting;
